import React from "react"
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageWrapper from "../components/layout/PageWrapper"
import Services from "../components/content/Services"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Gas fireplace and wood burning stoves installation"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design, Installation and Maintenance"
    />
    <PageWrapper>
      <SectionTitle
        title="Fire Skilled Services"
        strapline="Fireplace and stoves design, installation, maintenance"
      />
      <Services />
    </PageWrapper>
  </Layout>
)

export default ServicesPage
