import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import services from "../../constants/servicesData"
import { sortQueryData } from "../../helpers/query"

const query = graphql`
  {
    images: allFile(
      sort: { fields: name }
      filter: { relativeDirectory: { eq: "pages/services/services-list" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Services = () => {
  const data = useStaticQuery(query)

  const images = sortQueryData(data).map(item => {
    return item.node.childImageSharp.fluid
  })

  return (
    <Wrapper className="section-center">
      {services.map(service => {
        const { id, title, description, link, showLink } = service
        return (
          <article className="project" key={id}>
            <Image fluid={images[id - 1]} className="project-img" />
            <div className="project-info">
              <h3>
                {showLink ? <Link to={link}>{title}</Link> : <>{title}</>}
              </h3>
              <p className="project-desc">{description}</p>
              <div className="project-links">
                {showLink && (
                  <Link to={link} className="btn btn--small">
                    Read more
                  </Link>
                )}
              </div>
            </div>
          </article>
        )
      })}
    </Wrapper>
  )
}

export default Services

const Wrapper = styled.main`
  .project {
    display: grid;
    @media screen and (min-width: 768px) {
      padding-bottom: var(--spacer-bottom-double);
    }
  }
  .project-img {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    height: 19rem;
    z-index: 1;
  }
  .project-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #555, #222);
    opacity: 0;
    transition: var(--transition);
  }
  .project:hover .project-img::after {
    opacity: 0;
  }
  .project-info {
    background: var(--color-white);
    padding: 2rem 2rem;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
  .project-info h3 {
    font-weight: 400;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 0;

    a {
      font-weight: 400;
      text-transform: none;
      font-size: 1.5rem;
      line-height: 1.25;
      letter-spacing: 0;
      padding: 0;
      color: var(--color-black);
      transition: var(--transition);

      &:hover {
        background: transparent;
        opacity: 0.7;
      }
    }
  }
  .project-desc {
    color: var(--color-primary-2);
  }
  .project-icon {
    color: var(--color-motif-1);
    font-size: 1.25rem;
    margin-right: 0.5rem;
    transition: var(--transition);
  }
  .project-icon:hover {
    color: var(--color-motif-2);
  }
  @media screen and (min-width: 576px) {
    .project-img {
      height: 19rem;
    }
  }
  @media screen and (min-width: 768px) {
    .project-img {
      height: 22rem;

      &:after {
        opacity: 0.55;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .project {
      grid-template-columns: repeat(12, 1fr);
      align-items: center;
    }
    .project-img {
      grid-column: 1 / span 8;
      grid-row: 1 / 1;
      height: 30rem;
      border-radius: var(--radius);
      box-shadow: var(--dark-shadow);
    }
    .project-info {
      border-radius: var(--radius);
      box-shadow: var(--dark-shadow);
      z-index: 1;
      grid-column: 5 /12;
      grid-row: 1 / 1;
    }
    .project:nth-of-type(even) .project-img {
      grid-column: 5 / -1;
      grid-row: 1 / 1;
    }
    .project:nth-of-type(even) .project-info {
      grid-column: 2 / span 7;
      grid-row: 1 / 1;
      text-align: left;
    }
  }
`
