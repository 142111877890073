export default [
  {
    id: 1,
    title: "Gas, Solid Fuel and Bio Fuel Fireplaces Installation",
    description: `We supply and install entire fireplaces or just the gas burners (manual or remote controlled), to any custom size fireplace or any existing basket. We can advice you the best possible option for your fireplace to keep it efficient and safe to use.`,
    link: "/services/gas-fireplace-installers",
    showLink: true,
  },
  {
    id: 2,
    title: "Gas and wood burning Stoves Installation",
    description: `Safe and efficient solid fuel or gas stove can be the perfect solution for any home. With our huge range of different types and styles, from contemporary to classic ones, we can ensure to find an elegant stove to complement you contemporary or traditional interior.`,
    link: "/services/gas-stoves-installers",
    showLink: true,
  },
  {
    id: 4,
    title: "Supplying gas fireplaces and stoves equipment",
    description: `We closely cooperate with fireplaces manufactures and suppliers thereby, we're able to offer you different design and option for you home. From bespoke fireplaces to different effects for gas burners, including coal and high definition logs.`,
    link: "/services/gas-bio-fuel-fireplace-burners",
    showLink: true,
  },
  {
    id: 3,
    title: "Chimney sweeping and repairs, flue liners, chimney pots and cowls",
    description: `Fireplace and stove installation projects usually require additional services like complete chimney flue inspection. Our expert can provide a full inspection into your chimney (smoke test, draw test, pressure test) and provide any necessary services.`,
    link: "/services/flue-liners-chimney-sweeping",
    showLink: true,
  },
  {
    id: 5,
    title: "Designing bespoke fireplaces and chimney systems",
    description: `Prior the installation we can advice the best possible solution to your fireplace, stove or gas burner. We can simply supply a drawing showing the fireplace with a different designs options or even design everything from scratch with entire chimney system.`,
    link: "/services/fireplace-design",
    showLink: true,
  },
]
